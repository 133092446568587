export enum classroomResourceOptions {
  Activites = 'Activities',
  Stories = 'Stories',
  TeachingTips = 'Teaching tips',
  Other = 'Other',
}

export enum businessResourceOptions {
  Financial = 'Financial',
  AdministrationPolicies = 'Administration & policies',
  DBERegistration = 'DBE registration',
  Other = 'Other',
}

export enum DataFree {
  Yes = 'Yes',
  No = 'No',
}

export enum Likes {
  Zero = '0 likes',
  OneToTen = '1 to 10 likes',
  ElevenToFifty = '11 to 50 likes',
  FiftyOneToHundred = '51 to 100 likes',
  MoreThanHundred = 'More than 100 likes',
}
